import adminRoutes from "admin/meta_routes";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";

// import Icon from "../Icon";
import LinksGroup from "./LinksGroup/LinksGroup";
import {generateNavItems} from "./NavItems";

import s from "./Sidebar.module.scss";

const Sidebar = (props) => {
  const [navItems, setNavItems] = useState([]);
  const p = props.permissions || null;

  useEffect(() => {
    if (p) {
      setNavItems(generateNavItems(p));
    }
  }, [p]);
  return (
    <nav className={s.root}>
      <header className={s.logo}>
        <Link to={adminRoutes.home}>
          {/* <Icon glyph="logo" /> */}
        </Link>
      </header>
      <ul className={s.nav}>
        {navItems.map((navItem, i) => {
          return (
            <LinksGroup
              key={i}
              header={navItem.label}
              icon={navItem.icon || "/admin"}
              headerLink={navItem.to}
              {...(navItem.content
                ? {
                    childrenLinks: navItem.content.map((item) => ({
                      name: item.label,
                      link: item.to || "/admin",
                    })),
                  }
                : {})}
            />
          );
        })}
      </ul>
    </nav>
  );
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    permissions: store.permissions,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
