import React from 'react';
import metaRoutes from 'admin/meta_routes';

const Dashboard = React.lazy(() => import('admin/views/Dashboard/Dashboard'));
const CreateReport = React.lazy(() => import('admin/views/CreateReport/ReportList'));
const FileDump = React.lazy(() => import('./views/FileDump'));

const Roles = React.lazy(() => import('admin/views/Roles/Roles'));
const AddRole = React.lazy(() => import('admin/views/Roles/AddRole'));
const EditRole = React.lazy(() => import('admin/views/Roles/EditRole'));
const ViewRole = React.lazy(() => import('admin/views/Roles/ViewRole'));

const Users = React.lazy(() => import('admin/views/Users/Users'));
const AddUsers = React.lazy(() => import('admin/views/Users/AddUser'));
const EditUsers = React.lazy(() => import('admin/views/Users/EditUser'));

const TelephoneDirectory = React.lazy(() => import('admin/views/TelephoneDirectory/List'));
const Dictionary = React.lazy(() => import('admin/views/Dictionary/List'));
const KaryaSamiti = React.lazy(() => import('admin/views/KaryaSamiti/List'));
const Gallery = React.lazy(() => import('admin/views/Gallery/List'));

const CultureList = React.lazy(() => import('admin/views/Culture/List'));
const AddCulture = React.lazy(() => import('admin/views/Culture/Add'));
const EditCulture = React.lazy(() => import('admin/views/Culture/Edit'));
const ViewCulture = React.lazy(() => import('admin/views/Culture/View'));

const EventList = React.lazy(() => import('admin/views/Event/List'));
const AddEvent = React.lazy(() => import('admin/views/Event/Add'));
const EditEvent = React.lazy(() => import('admin/views/Event/Edit'));
const ViewEvent = React.lazy(() => import('admin/views/Event/View'));

const NewsAndNoticeList = React.lazy(() => import('admin/views/NewsAndNotice/List'));
const AddNewsAndNotice = React.lazy(() => import('admin/views/NewsAndNotice/Add'));
const EditNewsAndNotice = React.lazy(() => import('admin/views/NewsAndNotice/Edit'));
const ViewNewsAndNotice = React.lazy(() => import('admin/views/NewsAndNotice/View'));

const BookAndDocumentList = React.lazy(() => import('admin/views/BookAndDocument/List'));
const AddBookAndDocument = React.lazy(() => import('admin/views/BookAndDocument/Add'));
const EditBookAndDocument = React.lazy(() => import('admin/views/BookAndDocument/Edit'));
const ViewBookAndDocument = React.lazy(() => import('admin/views/BookAndDocument/View'));

const AdvertisementList = React.lazy(() => import('admin/views/Advertisement/List'));
const AddAdvertisement = React.lazy(() => import('admin/views/Advertisement/Add'));
const EditAdvertisement = React.lazy(() => import('admin/views/Advertisement/Edit'));
// const ViewAdvertisement = React.lazy(() => import('admin/views/Advertisement/View'));

const RestaurantList = React.lazy(() => import('admin/views/Restaurant/List'));
const AddRestaurant = React.lazy(() => import('admin/views/Restaurant/Add'));
const EditRestaurant = React.lazy(() => import('admin/views/Restaurant/Edit'));

const AboutUs = React.lazy(() => import('admin/views/AboutUs/Add'));

const exportRoutes = [
  { path: metaRoutes.home, exact: true, name: 'Dashboard', component: Dashboard },
  { path: metaRoutes.aboutUs, exact: true, name: 'About Us', component: AboutUs, permission: 'other.aboutUs' },
  { path: metaRoutes.createReports, exact: true, name: 'Create Report', component: CreateReport, permission: 'isAdmin' },
  { path: metaRoutes.fileDump, exact: true, name: 'File Upload', component: FileDump, permission: 'other.fileDump' },
  { path: metaRoutes.roles, exact: true, name: 'Roles', component: Roles, permission: 'role.view' },
  { path: metaRoutes.rolesAdd, exact: true, name: 'Add Role', component: AddRole, permission: 'role.add' },
  { path: metaRoutes.rolesEdit, exact: true, name: 'Edit Role', component: EditRole, permission: 'role.edit' },
  { path: metaRoutes.rolesView, name: 'View Role', component: ViewRole, permission: 'role.view' },
  { path: metaRoutes.users, exact: true, name: 'Users', component: Users, permission: 'user.view' },
  { path: metaRoutes.usersAdd, exact: true, name: 'Add Users', component: AddUsers, permission: 'user.add' },
  { path: metaRoutes.usersEdit, exact: true, name: 'Edit Users', component: EditUsers, permission: 'user.edit' },
  { path: metaRoutes.telephoneDirectory, exact: true, name: 'Telephone Directory', component: TelephoneDirectory, permission: 'telephone.view' },
  { path: metaRoutes.dictionary, exact: true, name: 'Dictionary', component: Dictionary, permission: 'dictionary.view' },
  { path: metaRoutes.karyaSamiti, exact: true, name: 'Karya Samiti', component: KaryaSamiti, permission: 'karyaSamiti.view' },
  { path: metaRoutes.gallery, exact: true, name: 'Gallery', component: Gallery, permission: 'gallery.view' },
  { path: metaRoutes.cultureList, exact: true, name: 'Cultures', component: CultureList, permission: 'culture.view' },
  { path: metaRoutes.cultureAdd, exact: true, name: 'Add Culture', component: AddCulture, permission: 'culture.add' },
  { path: metaRoutes.cultureEdit, exact: true, name: 'Edit Culture', component: EditCulture, permission: 'culture.edit' },
  { path: metaRoutes.cultureView, name: 'View Culture', component: ViewCulture, permission: 'culture.view' },
  { path: metaRoutes.eventList, exact: true, name: 'Events', component: EventList, permission: 'eventAndActivity.view' },
  { path: metaRoutes.eventAdd, exact: true, name: 'Add Event', component: AddEvent, permission: 'eventAndActivity.add' },
  { path: metaRoutes.eventEdit, exact: true, name: 'Edit Event', component: EditEvent, permission: 'eventAndActivity.edit' },
  { path: metaRoutes.eventView, name: 'View Event', component: ViewEvent, permission: 'eventAndActivity.view' },
  { path: metaRoutes.newsAndNoticeList, exact: true, name: 'News and Notices', component: NewsAndNoticeList, permission: 'newsAndNotice.view' },
  { path: metaRoutes.newsAndNoticeAdd, exact: true, name: 'Add News and Notice', component: AddNewsAndNotice, permission: 'newsAndNotice.add' },
  { path: metaRoutes.newsAndNoticeEdit, exact: true, name: 'Edit News and Notice', component: EditNewsAndNotice, permission: 'newsAndNotice.edit' },
  { path: metaRoutes.newsAndNoticeView, name: 'View News and Notice', component: ViewNewsAndNotice, permission: 'newsAndNotice.view' },
  
  { path: metaRoutes.bookAndDocumentList, exact: true, name: 'Book and Documents', component: BookAndDocumentList, permission: 'bookAndDocument.view' },
  { path: metaRoutes.bookAndDocumentAdd, exact: true, name: 'Add Book and Document', component: AddBookAndDocument, permission: 'bookAndDocument.add' },
  { path: metaRoutes.bookAndDocumentEdit, exact: true, name: 'Edit Book and Document', component: EditBookAndDocument, permission: 'bookAndDocument.edit' },
  { path: metaRoutes.bookAndDocumentView, name: 'View Book and Document', component: ViewBookAndDocument, permission: 'bookAndDocument.view' },
  
  { path: metaRoutes.advertisementList, exact: true, name: 'Advertisements', component: AdvertisementList, permission: 'advertisement.view' },
  { path: metaRoutes.advertisementAdd, exact: true, name: 'Add Advertisement', component: AddAdvertisement, permission: 'advertisement.add' },
  { path: metaRoutes.advertisementEdit, exact: true, name: 'Edit Advertisement', component: EditAdvertisement, permission: 'advertisement.edit' },
  // { path: metaRoutes.advertisementView, name: 'View Advertisement', component: ViewAdvertisement, permission: 'karyaSamiti.edit' },
  { path: metaRoutes.restaurantList, exact: true, name: 'Restaurants', component: RestaurantList, permission: 'restaurant.view' },
  { path: metaRoutes.restaurantAdd, exact: true, name: 'Add Restaurant', component: AddRestaurant, permission: 'restaurant.add' },
  { path: metaRoutes.restaurantEdit, exact: true, name: 'Edit Restaurant', component: EditRestaurant, permission: 'restaurant.edit' },


];

export default exportRoutes;
