import React, {useEffect} from "react";
import "../scss/theme.scss";
import AdminLogin from "./AdminLogin";
import LayoutComponent from "./Layout";
import {connect} from "react-redux";
import {authorize} from "admin/redux/actions/authAction";

const AdminContainer = (props) => {
  useEffect(() => {
    props.dispatch(authorize());
  }, []); //eslint-disable-line

  if (!props.authUser.isAuthenticated) {
    if (props.authUser.isLoading) return null;
    else return <AdminLogin />;
  }

  if (props.permissions.isLoading) return null;

  return <LayoutComponent />;
};

export default connect((state) => ({
  authUser: state.authUser,
  permissions: state.permissions,
}))(AdminContainer);
