const adminRoutes = {
  home: "/admin",
  login: "/admin/login",
  aboutUs: "/admin/about-us",
  // CHARTS
  createReports: "/admin/create-reports",
  // EXCEL DUMP
  fileDump: "/admin/dump",
  //  USERS
  users: "/admin/users",
  usersAdd: "/admin/users/add",
  usersEdit: "/admin/users/edit",
  usersView: "/admin/users/:id",
  //  ROLES
  roles: "/admin/roles",
  rolesAdd: "/admin/roles/add",
  rolesView: "/admin/roles/:id",
  rolesEdit: "/admin/roles/edit",

  telephoneDirectory: "/admin/telephone-directory",
  dictionary: "/admin/dictionary",
  karyaSamiti: "/admin/karya-samiti",
  gallery: "/admin/gallery",

  cultureList: "/admin/culture",
  cultureAdd: "/admin/culture/add",
  cultureEdit: "/admin/culture/edit",
  cultureView: "/admin/culture/view",

  eventList: "/admin/event-activities",
  eventAdd: "/admin/event-activities/add",
  eventEdit: "/admin/event-activities/edit",
  eventView: "/admin/event-activities/view",

  newsAndNoticeList: "/admin/news-and-notices",
  newsAndNoticeAdd: "/admin/news-and-notices/add",
  newsAndNoticeEdit: "/admin/news-and-notices/edit",
  newsAndNoticeView: "/admin/news-and-notices/view",

  bookAndDocumentList: "/admin/book-and-documents",
  bookAndDocumentAdd: "/admin/book-and-documents/add",
  bookAndDocumentEdit: "/admin/book-and-documents/edit",
  bookAndDocumentView: "/admin/book-and-documents/view",

  advertisementList: "/admin/advertisements",
  advertisementAdd: "/admin/advertisements/add",
  advertisementEdit: "/admin/advertisements/edit",
  advertisementView: "/admin/advertisements/view",

  restaurantList: "/admin/restaurants",
  restaurantAdd: "/admin/restaurants/add",
  restaurantEdit: "/admin/restaurants/edit",
  restaurantView: "/admin/restaurants/view",
};

export default adminRoutes;
